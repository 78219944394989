<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >Past Experience (Documents shall be added only in PDF format)</span
        >
      </v-flex>

      <v-flex xs12 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Experience certificate from last organization</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.experienceCertificateLastOrg">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.experienceCertificateLastOrg">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View
                  </v-btn>
                </a>
                &nbsp;
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.experienceCertificateLastOrg.click()"
                  style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                >
                 EDIT
                </v-btn>
                <input
                  v-show="false"
                  id="file"
                  ref="experienceCertificateLastOrg"
                  type="file"
                  accept="application/pdf"
                  @change="experienceCertificateLastOrgUpload($event)"
                />
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-chip
                v-if="experienceCertificateLastOrg.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ experienceCertificateLastOrg.name.slice(0, 40) }}
                      <span v-if="experienceCertificateLastOrg.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
            
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-else>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="experienceCertificateLastOrg.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ experienceCertificateLastOrg.name.slice(0, 40) }}
                        <span v-if="experienceCertificateLastOrg.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.experienceCertificateLastOrg.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="experienceCertificateLastOrg"
                  type="file"
                  accept="application/pdf"
                  @change="experienceCertificateLastOrgUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Salary Slip</span>
          </v-flex>
           <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.salarySlip">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.salarySlip">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View
                  </v-btn>
                </a>
                &nbsp;
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.salarySlip.click()"
                  style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                >
              Edit
                </v-btn>
                <input
                  v-show="false"
                  id="file"
                  ref="salarySlip"
                  type="file"
                  accept="application/pdf"
                  @change="salarySlipUpload($event)"
                />
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-chip
                v-if="salarySlip.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ salarySlip.name.slice(0, 40) }}
                      <span v-if="salarySlip.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
           
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-else>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="salarySlip.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ salarySlip.name.slice(0, 40) }}
                        <span v-if="salarySlip.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.salarySlip.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="salarySlip"
                  type="file"
                  accept="application/pdf"
                  @change="salarySlipUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">CV</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.cv">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.cv">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                   CV
                  </v-btn>
                </a>
                &nbsp;
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.cv.click()"
                  style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                >
                  Edit
                </v-btn>
                <input
                  v-show="false"
                  id="file"
                  ref="cv"
                  type="file"
                  accept="application/pdf"
                  @change="cvUpload($event)"
                />
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-chip
                v-if="cv.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ cv.name.slice(0, 40) }}
                      <span v-if="cv.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
            
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-else>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="cv.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ cv.name.slice(0, 40) }}
                        <span v-if="cv.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.cv.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="cv"
                  type="file"
                  accept="application/pdf"
                  @change="cvUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
     
        <v-divider class="my-6"></v-divider>
        
        <v-layout wrap>
          <v-flex xs12>
            <span class="kumbhBold" style="font-size: 16px">Add Experience Details</span>
          </v-flex>
        </v-layout>
        <v-flex pt-3> 


        <v-card outlined class="pa-4">

        <v-layout wrap pt-4 v-for="(expe, index) in experiences" :key="index">

          <v-flex xs12>
            
              <v-layout wrap align-center>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="expe.details"
                    label="Experience Details" 
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
             
                <v-flex xs12 sm6 text-right v-if="expe.experienceFile.length>0">
                  <v-layout wrap>
                    <v-flex xs12>
                      <a target="_blank" :href="mediaURL + expe.experienceFile">
                        <v-btn
                          style="border-radius: 14px; border: 0.5px solid #707070"
                          px-5
                          pt-0
                          text
                          small
                          outlined
                        >
                          View Experience Certificate  
                        </v-btn>
                      </a>
                      &nbsp;
                      <v-icon
                      color="red"
                      small
                   
                        @click="openDeleteDialog(expe._id, index)"
                        
                      style="cursor: pointer; font-size: 18px;"
                    >
                      mdi-delete
                    </v-icon>


                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 text-right v-else>
                  <v-chip
                    v-if="expe.experienceFile.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <span>{{ expe.experienceFile.name.slice(0, 40) }}
                      <span v-if="expe.experienceFile.name.length > 40">...</span>
                    </span>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs['expe'+index][0].click()">
                    <span>UPLOAD</span>
                  </v-btn>
                  <input
                    v-show="false"
                    :ref="'expe'+index"
                    type="file"
                    accept="application/pdf"
                    @change="(e) => expFileUpload(e, index)"
                  />
                  <v-icon v-if="experiences.length > 1"
  color="error"
  small
  class="ml-2"
  @click="removeExpe(index)"

>mdi-close</v-icon>

                </v-flex>


                

                
              </v-layout>
             
     
           
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end pt-4 v-if="experiences.length < 5">
          <v-btn small color="#00994d" dark @click="addExpField">
            <v-icon left>mdi-plus</v-icon>
            Add More Experience Details
          </v-btn>
        </v-layout>
      </v-card>


      <v-layout wrap pt-5 justify-start>
        <v-flex xs12 sm12 md12 lg6 v-if="mydocs && mydocs.documentDetails">
          <span class="kumbhBold">
            Total experience in years
        </span>
          <v-text-field  hide-details="true" v-model="mydocs.documentDetails.totalExperienceinYears" class="pt-2"  label="  Total experience in years" outlined
          dense></v-text-field>
        </v-flex>
        </v-layout>
    </v-flex>

        <v-layout wrap justify-end py-5>
          <v-flex xs12 text-end>
            <v-btn color="#005f32" dark @click="validateAndAdd()">
              <span>Save Details</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

     <!-- Confirmation Dialog -->
 <v-dialog persistent v-model="deleteDialog" max-width="600px">
  <v-card>
    <v-layout wrap>
      <v-flex
        xs12
        pa-4
        text-left
        align-self-center
        style="background: #005f32 !important"
      >
        <v-layout wrap>
          <v-flex xs6 align-self-center text-left>
            <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
              Delete Degree</span
            >
          </v-flex>
          <v-flex xs6 align-self-center text-right>
            <v-btn
              icon
              color="white"
              text
              @click="deleteDialog = false"
            >
              <v-icon color="#ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pa-4></v-flex>
      <v-flex xs12>
        <v-layout wrap px-5 pb-8>
          <v-flex xs12 align-self-center text-left>
            <span class="kumbhBold" style="color: #000; font-size: 20px"
              >Are you sure you want to delete this past experience details?</span
            >
          </v-flex>

          <v-flex xs12 align-self-center text-right pt-3>
            <v-btn     @click="deleteDialog = false" color="white">
              <span class="kumbhBold" style="color: black"> Cancel </span>
            </v-btn> &nbsp;
            <v-btn   @click="confirmDelete" color="#005f32">
              <span class="kumbhBold" style="color: white"> Delete </span>
            </v-btn></v-flex
          >
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</v-dialog>


  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      salarySlip: "",
      cv:"",
      experienceCertificateLastOrg: "",
      totalExperienceinYears:"",
      experiences: [
        {details: "", experienceFile: "", _id: null,}
      ],
      formData: new FormData(),
      mydocs:{},

      deleteDialog: false,
      deleteExpId: null,
      deleteExpIndex: null,
    };
  },
  mounted() {
    this.getMyUploads();
  },
  methods: {
    salarySlipUpload(event) {
        this.salarySlip = event.target.files[0];
      },
      experienceCertificateLastOrgUpload(event) {
        this.experienceCertificateLastOrg = event.target.files[0];
      },
      cvUpload(event) {
        this.cv = event.target.files[0];
      },

      
 expFileUpload(event, index) {
      this.experiences[index].experienceFile = event.target.files[0];
    },
    addExpField() {
      if (this.experiences.length < 5) {
        this.experiences.push({details: "", experienceFile: "", _id: null,});
      }
    },
    removeExpe(index) {
  this.experiences.splice(index, 1); // Remove the experience at the given index

  // Only add a blank experience if the array is empty
  if (this.experiences.length === 0) {
    this.experiences.push({ details: "", experienceFile: "" , _id: null,});
  }
},
    getMyUploads() {
    this.appLoading = true;
    axios({
      url: "/experienceDetails/view",
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        id: localStorage.getItem("userid"),
      },
    })
      .then((response) => {
        this.appLoading = false;
        if (response.data.status) {
          this.mydocs = response.data.data;

          // Populate experiences array with response data for "pastExperience"
          if (this.mydocs.documentDetails.pastExperienceDetails) {
            this.experiences = this.mydocs.documentDetails.pastExperienceDetails.map(expe => ({
              _id: expe._id,
              details: expe.details,
              experienceFile: expe.experienceFile
            }));
          }

        } else {
          this.showSnackBar = true;
          this.msg = response.data.msg;
        }
      })
      .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.log(err);
      });
  },



  validateForm() {
     
     

     // Validate that all required files are uploaded
     

    //  if (!this.experienceCertificateLastOrg && !this.mydocs?.documentDetails?.experienceCertificateLastOrg) {
    //    this.msg = "Please upload experience certificate from last organization";
    //    this.showSnackBar = true;
    //    return false;
    //  }
    //  if (!this.salarySlip && !this.mydocs?.documentDetails?.salarySlip) {
    //    this.msg = "Please upload Salary Slip";
    //    this.showSnackBar = true;
    //    return false;
    //  }

     if (!this.cv && !this.mydocs?.documentDetails?.cv) {
       this.msg = "Please upload CV";
       this.showSnackBar = true;
       return false;
     }


   
     // Validate file sizes (max 5MB)
     const maxSize = 5 * 1024 * 1024; // 5MB in bytes
     if (this.twelthDMC && this.twelthDMC.size > maxSize) {
       this.msg = "Experience certificate size should be less than 5MB";
       this.showSnackBar = true;
       return false;
     }

     if (this.tenthDMC && this.tenthDMC.size > maxSize) {
       this.msg = "Salary Slip size should be less than 5MB";
       this.showSnackBar = true;
       return false;
     }

     if (this.graduationDegree && this.graduationDegree.size > maxSize) {
       this.msg = "CV size should be less than 5MB";
       this.showSnackBar = true;
       return false;
     }
   

     return true;
   },

   validateAndAdd() {
     if (this.validateForm()) {
       this.add();
     }
   },



    add() {
  let formData = new FormData();
  formData.append("id", localStorage.getItem("userid"));

  // Add individual files
  formData.append("salarySlip", this.salarySlip);
  formData.append("totalExperienceinYears", this. mydocs.documentDetails.totalExperienceinYears);
 formData.append("experienceCertificateLastOrg", this.experienceCertificateLastOrg);
  formData.append("cv", this.cv);

  // formData.append("graduationDegree", this.graduationDegree);
  // formData.append("postGraduationDegree", this.postGraduationDegree);
  // formData.append("CV", this.cv);

  // Prepare experiences array
  // const expArray = this.experiences
  //   .filter(expe => expe.details && expe.experienceFile)  // Only include entries with both fields filled
  //   .map(expe => ({
  //     details: expe.details,
  //     experienceFile: expe.experienceFile.name  // Use the file name here
  //   }));

   const expArray = this.experiences.map(expe => ({
    _id: expe._id,
    details: expe.details,
    experienceFile: expe.experienceFile.length > 0 ? expe.experienceFile : expe.experienceFile.name // Use existing file path or new file name
  }));


  // Add experiences array to formData as a JSON string
  formData.append("pastExperience", JSON.stringify(expArray));

  // Append actual files to formData
  this.experiences.forEach((expe) => {
    if (expe.experienceFile) {
      formData.append("pastExperienceDetails", expe.experienceFile);
    }
  });

  // Send form data via Axios
  axios({
    method: "POST",
    url: "/employee/pastexperience/upload",
    data: formData,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
    .then((response) => {
      if (response.data.status == true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.salarySlip = "";
        this.experienceCertificateLastOrg = "";
        this.cv = "";
        this.getMyUploads();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
// deleteExpe(expe, index) {
//     const data = { pastExperienceId : expe };

//     axios({
//       url: "/employee/document/deletePastExperienceFile",
//       method: "POST",
//       headers: {
//         "x-auth-token": localStorage.getItem("token"),
//       },
//       data: data,
//     })
//       .then((response) => {
//         this.appLoading = false;
//         if (response.data.status) {
//           this.msg = "Delete Successfully";
//           this.showsnackbar = true;
// this.getMyUploads();
//           // Remove the expe locally after successful deletion from the server
//           this.experiences.splice(index, 1);
//         } else {
//           this.msg = response.data.msg;
//           this.showsnackbar = true;
//         }
//       })
//       .catch((err) => {
//         this.appLoading = false;
//         this.ServerError = true;
//         console.log(err);
//       });
//   }

  openDeleteDialog(expId, index) {
      this.deleteExpId = expId;
      this.deleteExpIndex = index;
      this.deleteDialog = true;
    },

    // Confirm deletion and call deleteDegree()
    confirmDelete() {
      this.deleteDialog = false;
      this.deleteExpe(this.deleteExpId, this.deleteExpIndex);
    },

    deleteExpe(expe, index) {
      const data = { pastExperienceId : expe };

      axios({
        url: "/employee/document/deletePastExperienceFile",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Successfully";
            this.showSnackBar = true;
            this.getMyUploads();
            // Remove the degree locally after successful deletion from the server
            this.degrees.splice(index, 1);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

  },
};
</script>