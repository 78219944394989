<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >Personal Details (Photograph shall be added only in png/jpg format)</span
        >
      </v-flex>
      <v-flex xs12 pt-5>
      
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Personal Email</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field 
                  v-model="mydocs.documentDetails.personalEmail"
                  label="Email"
                  outlined
                  dense
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Gender</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.employeeDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-select
                  v-model="mydocs.employeeDetails.gender"
                  :items="['Male', 'Female', 'Other']"
                  label="Gender"
                  outlined
                  dense
                  :rules="[v => !!v || 'Gender is required']"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Date of birth</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.employeeDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-menu
                  ref="menu" disabled
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="mydocs.employeeDetails.dob"
                      label="DOB"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Date of birth is required']"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mydocs.employeeDetails.dob"
                    @input="menu = false"
                    :max="getMaxDate()"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Blood Group</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <!-- <v-text-field
                  v-model="mydocs.documentDetails.bloodGroup"
                  label="Blood Group"
                  outlined
                  dense
                  :rules="[v => !!v || 'Blood group is required']"
                  required
                ></v-text-field> -->
                <v-select
                v-model="mydocs.documentDetails.bloodGroup"
                :items="['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']"
                label="Blood Group"
                outlined
                dense
                :rules="[v => !!v || 'Blood group is required']"
                required
              ></v-select>
              
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Marital Status</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-select
                  v-model="mydocs.documentDetails.maritalStatus"
                  :items="['Married', 'Unmarried','Widow','Widower','Divorcee']"
                  label="Marital Status"
                  outlined
                  dense
                  :rules="[v => !!v || 'Marital status is required']"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Photograph</span>
          </v-flex>
          <v-flex xs12 sm6 md8>
            <v-card outlined class="pa-3">
            <v-layout
              wrap
              row
              align-center
             
            >
   
              <!-- Display the cropped image if available (newly uploaded image) -->
              <v-img
                v-if="croppedImage"
                :src="croppedImage"
                max-width="100"
                max-height="100"
                class="mb-3"
              ></v-img>
        
              <!-- Display existing photo only if no cropped image is available -->
              <v-img
                v-else-if="mydocs && mydocs.employeeDetails && mydocs.employeeDetails.photo"
                :src="mediaURL + mydocs.employeeDetails.photo"
                max-width="100"
                max-height="100"
                class="mb-3 mt-2"
              ></v-img>
        
              <!-- Upload New or Upload button based on photo existence -->
              <v-btn
              color="#005f32"
              dark
              small
              @click="$refs.photo.click()"
              class="ml-6 mt-4 my-4"
            >
              <v-icon left small v-if="mydocs && mydocs.employeeDetails && mydocs.employeeDetails.photo">
                mdi-pencil
              </v-icon>
              <span style="font-size: 12px;">
                {{ mydocs && mydocs.employeeDetails && mydocs.employeeDetails.photo ? 'Edit Image' : 'UPLOAD IMAGE' }}
              </span>
            </v-btn>
            
              <!-- <v-btn
                color="#005f32"
                dark
                small
                @click="$refs.photo.click()"
                class="ml-3"
              >
                <span style="font-size: 12px;">{{ mydocs && mydocs.employeeDetails && mydocs.employeeDetails.photo ? 'Edit Image' : 'UPLOAD' }}</span>
              </v-btn> -->
        
              <!-- Hidden file input for image upload -->
              <input
                v-show="false"
                ref="photo"
                type="file"
                accept="image/*"
                @change="onImageSelected"
              />
        
              <!-- Image Cropper Modal -->
              <v-dialog v-model="dialog" max-width="500">
                <v-card>
                  <v-card-title class="headline">Crop your image</v-card-title>
                  <v-card-text>
                    <Cropper
                      ref="cropper"
                      :src="imagePreview"
                      :aspect-ratio="1"
                      :view-mode="1"
                      style="width: 100%; height: 300px;"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="cropImage">Crop</v-btn>
                    <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
    <v-layout wrap justify-end py-5>
      <v-flex xs12 text-end>
        <v-btn color="#005f32" dark @click="validateAndAdd()"
          ><span>Save Details</span></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import axios from "axios";
export default {
  components: {
    Cropper,
  },
  data() {
    return {
      photograph: null,
      imagePreview: null,
      croppedImage: null,
      dialog: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      appLoading: false,
      mydocs:{},
      ServerError: false,
      dob: '',
      menu: false,
      bloodGroup: "",
      personalEmail: "",
      gender: "",
      maritalStatus: "",
      formData: new FormData(),
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
    };
  },
  mounted() {
    this.getMyUploads();
  },
  methods: {
    getMaxDate() {
      const today = new Date();
      const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      return eighteenYearsAgo.toISOString().substr(0, 10);
    },

    validateForm() {
      if (!this.mydocs.documentDetails?.personalEmail || 
          !/.+@.+\..+/.test(this.mydocs.documentDetails.personalEmail)) {
        this.msg = "Please enter a valid email";
        this.showSnackBar = true;
        return false;
      }

      if (!this.mydocs.employeeDetails?.gender) {
        this.msg = "Please select gender";
        this.showSnackBar = true;
        return false;
      }

      if (!this.mydocs.employeeDetails?.dob) {
        this.msg = "Please select date of birth";
        this.showSnackBar = true;
        return false;
      }

      if (!this.mydocs.documentDetails?.bloodGroup) {
        this.msg = "Please enter blood group";
        this.showSnackBar = true;
        return false;
      }

      if (!this.mydocs.documentDetails?.maritalStatus) {
        this.msg = "Please select marital status";
        this.showSnackBar = true;
        return false;
      }

      if (!this.photograph && !this.mydocs.employeeDetails?.photo) {
        this.msg = "Please upload a photograph";
        this.showSnackBar = true;
        return false;
      }

      return true;
    },

    validateAndAdd() {
      if (this.validateForm()) {
        this.add();
      }
    },

    getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/employee/document/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("userid"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },

    onImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.photograph = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
          this.dialog = true;
        };
        reader.readAsDataURL(file);
      }
    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      if (result) {
        result.canvas.toBlob((blob) => {
          this.croppedImage = URL.createObjectURL(blob);
          this.photograph = blob;
          this.dialog = false;
        });
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    add() {
      let formData = new FormData();
      formData.append("id", localStorage.getItem("userid"));
      formData.append("photo", this.photograph);
      formData.append("bloodGroup", this.mydocs.documentDetails.bloodGroup);
      formData.append("dob", this.mydocs.employeeDetails.dob);
      formData.append("personalEmail", this.mydocs.documentDetails.personalEmail);
      formData.append("gender", this.mydocs.employeeDetails.gender);
      formData.append("maritalStatus", this.mydocs.documentDetails.maritalStatus);

      axios({
        method: "POST",
        url: "/employee/personaldocument/upload",
        data: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            formData = new FormData();
            this.photograph = "";
            this.bloodGroup = "";
            this.dob = "";
            this.personalEmail = "";
            this.gender = "";
            this.maritalStatus = "";
            this.appLoading = false;
            this.getMyUploads();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            formData = new FormData();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
